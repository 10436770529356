import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import FullScreenSection from './FullScreenSection';
import useSubmit from '../hooks/useSubmit';
import { useAlertContext } from '../context/alertContext';

const LandingSection = () => {
  const { isLoading, response, submit } = useSubmit();
  const { onOpen } = useAlertContext();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      type: 'hireMe',
      comment: '',
    },
    onSubmit: async (values) => {
      await submit(values);
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      email: Yup.string().email('Email is not valid').required('Required'),
      type: Yup.string(),
      comment: Yup.string()
        .min(15, 'Must be at least 15 characters')
        .required('Required'),
    }),
  });

  useEffect(() => {
    if (response !== null) {
      onOpen(response?.type, response?.message);
      if (response?.type === 'success') formik.resetForm();
    }
  }, [response]);

  return (
    <FullScreenSection
      isDarkBackground
      backgroundColor='gray.600'
      spacing={8}
      p={8}
    >
      <VStack w='100%' px={[2, 32]} my={8} alignItems='flex-start'>
        <Heading
          data-aos='zoom-in'
          data-aos-delay='600'
          alignSelf='center'
          as='h1'
          id='contactme-section'
        >
          Contact me
        </Heading>
        <Box data-aos='fade' data-aos-delay='600' p={6} rounded='md' w='100%'>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl
                isInvalid={formik.touched.firstName && formik.errors.firstName}
              >
                <FormLabel htmlFor='firstName'>Name</FormLabel>
                <Input
                  {...formik.getFieldProps('firstName')}
                  id='firstName'
                  name='firstName'
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.email && formik.errors.email}
              >
                <FormLabel htmlFor='email'>Email Address</FormLabel>
                <Input
                  {...formik.getFieldProps('email')}
                  id='email'
                  name='email'
                  type='email'
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor='type'>Type of enquiry</FormLabel>
                <Select
                  id='type'
                  name='type'
                  bg='teal.600'
                  {...formik.getFieldProps('type')}
                >
                  <option style={{ color: 'black' }} value='hireMe'>
                    Freelance project proposal
                  </option>
                  <option style={{ color: 'black' }} value='openSource'>
                    Open source consultancy session
                  </option>
                  <option style={{ color: 'black' }} value='other'>
                    Other
                  </option>
                </Select>
              </FormControl>
              <FormControl
                isInvalid={formik.touched.comment && formik.errors.comment}
              >
                <FormLabel htmlFor='comment'>Your message</FormLabel>
                <Textarea
                  {...formik.getFieldProps('comment')}
                  id='comment'
                  name='comment'
                  height={225}
                />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button
                data-aos='fade-in'
                data-aos-duration='800'
                type='submit'
                colorScheme='teal'
                width='full'
                style={{ transition: 'ease 300ms' }}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default LandingSection;
