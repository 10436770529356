import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box backgroundColor='blue.700'>
      <footer>
        <Flex
          margin='0 auto'
          color='white'
          justifyContent='space-around'
          alignItems='center'
          flexDirection='column'
          maxWidth='1024px'
          height={16}
          py={1}
        >
          <p>Bogdan Bacoșcă © 2023</p>

          <p>
            <span style={{ paddingRight: '5px' }}>
              <FontAwesomeIcon className='icon' icon={faPhone} size='1x' />
            </span>
            +40 720 910 777
          </p>
        </Flex>
      </footer>
    </Box>
  );
};
export default Footer;
