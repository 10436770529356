/**
 * AboutMeSection component renders a full width section
 * with the user's profile image, extracting the average color from the image
 * Imports the color.js package to extract the average color from the image.
 */
import React, { useEffect, useState } from 'react';
import image from '../images/profile.png';
import { Box, Image, Text, Heading } from '@chakra-ui/react';
import { average } from 'color.js';

const AboutMeSection = () => {
  const [bgColor, setBgColor] = useState('#fff'); // Set an initial bg color

  // Extract the average color from the image using color.js
  useEffect(() => {
    const getImageColor = async () => {
      try {
        const color = await average(image, {
          format: 'hex',
        });
        setBgColor(color);
        console.log('Color extracted:', color);
      } catch (error) {
        console.error('Error extracting color:', error);
      }
    };

    getImageColor();
  }, []); // Run this effect only once when the component mounts

  // Convert the hex color to rgba helper function
  const rgbaColor = hexToRgba(bgColor, 0.8);
  function hexToRgba(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <Box bg='blue.700' py='25px'>
      <Heading
        data-aos='zoom-in'
        as='h1'
        pb='20px'
        textAlign='center'
        color='white'
      >
        About me
      </Heading>
      <Box
        bg={rgbaColor}
        w='300px'
        h='300px'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        margin='auto'
        borderRadius='10px'
        p='15px'
      >
        <Image
          src={image}
          alt='profile'
          w='200px'
          h='200px'
          margin='auto'
          objectFit='cover'
          rounded='full'
          alignSelf='center'
          justifySelf='center'
        />
        <Text fontSize='20px' as='h1' color='white'>
          I'm Bogdan Bacosca
        </Text>
        <Text color='white'>A Front-End Developer</Text>
        <Text color='white'>... specialized in React</Text>
      </Box>
    </Box>
  );
};

export default AboutMeSection;
