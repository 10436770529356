import {
  Card,
  CardHeader,
  Button,
  CardBody,
  CardFooter,
  Image,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const CardComponent = ({ title, description, imageSrc, url }) => {
  return (
    <Card
      data-aos='fade-up'
      data-aos-delay='600'
      data-aos-duration='800'
      maxW='sm'
      justify='space-between'
    >
      <Image
        src={imageSrc}
        alt='logo'
        boxSize='sm'
        objectFit='cover'
        borderTopRadius='5px'
      />
      <Stack align='center' justify='center' textAlign='center'>
        <CardHeader>
          <Heading
            size='md'
            border='1px'
            borderLeft='4px'
            borderColor='teal.500'
            color='gray.700'
            padding='5px'
            borderRadius='6px'
          >
            {title}
          </Heading>
        </CardHeader>
        <CardBody>
          <Text color='gray.700' fontSize='sm'>
            {description}
          </Text>
        </CardBody>
        <CardFooter>
          <Button
            as='a'
            href={url}
            target='_blank'
            variant='solid'
            colorScheme='teal'
            data-aos='zoom-in'
            style={{ transition: 'ease 300ms' }}
          >
            See
          </Button>
        </CardFooter>
      </Stack>
    </Card>
  );
};

export default CardComponent;
