import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faFacebook,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { Box, HStack, Fade, Button } from '@chakra-ui/react';

const socials = [
  {
    icon: faEnvelope,
    url: 'mailto: contact@atbogdan.com',
  },
  {
    icon: faGithub,
    url: 'https://github.com/bogdanbacosca',
  },
  {
    icon: faFacebook,
    url: 'https://www.facebook.com/bacoscabogdan/',
  },
  {
    icon: faLinkedin,
    url: 'https://www.linkedin.com/in/atbogdan/',
  },
];

const Header = (props) => {
  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const { isContactOnScreen, isProjectsOnScreen } = props;

  return (
    <Box
      position='fixed'
      width='100%'
      boxShadow='lg'
      zIndex={5000}
      top={0}
      translateY={0}
      transitionProperty='transform'
      transitionDuration='.3s'
      transitionTimingFunction='ease-in-out'
      backgroundColor='blue.700'
    >
      <Box
        color='white'
        maxWidth='1280px'
        margin='0 auto'
        minHeight='100%'
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        alignItems='center'
        flexWrap='wrap'
      >
        <HStack
          px={16}
          py={4}
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <HStack as='nav' spacing={4}>
            {socials.map((item, i) => {
              return (
                <div key={i} className='fade'>
                  <a href={item.url} target='_blank'>
                    <FontAwesomeIcon
                      className='icon'
                      icon={item.icon}
                      size='2x'
                    />
                  </a>
                </div>
              );
            })}
          </HStack>

          <HStack
            className='fade'
            as='nav'
            spacing={8}
            pt={{ base: '15px', md: '0px' }}
          >
            {isProjectsOnScreen || (
              <Fade in={!isProjectsOnScreen}>
                <Button
                  as='a'
                  colorScheme='teal'
                  size='sm'
                  href='/#projects'
                  onClick={handleClick('projects')}
                >
                  Projects
                </Button>
              </Fade>
            )}
            {isContactOnScreen || (
              <Fade in={!isContactOnScreen}>
                <Button
                  as='a'
                  colorScheme='teal'
                  size='sm'
                  href='/#contact-me'
                  onClick={handleClick('contactme')}
                >
                  Contact Me
                </Button>
              </Fade>
            )}
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
