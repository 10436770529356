import * as React from 'react';
import { VStack } from '@chakra-ui/react';

const FullScreenSection = ({ children, isDarkBackground, ...boxProps }) => {
  return (
    <VStack
      backgroundColor={boxProps.backgroundColor}
      color={isDarkBackground ? 'white' : 'black'}
    >
      <VStack
        position='relative'
        w='100%'
        minHeight='100vh'
        {...boxProps}
        zIndex='1'
      >
        {children}
      </VStack>
    </VStack>
  );
};

export default FullScreenSection;
