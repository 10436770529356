import React from 'react';
import FullScreenSection from './FullScreenSection';
import { Heading, SimpleGrid } from '@chakra-ui/react';
import CardComponent from './Card';

const projects = [
  {
    title: 'Lucky Shrub',
    description:
      'Business landing page featuring a responsive layout made for both desktop and mobile, animations and cool effects. Built with plain HTML and CSS.',
    getImageSrc: () => require('../images/lucky-shrub.png'),
    url: 'https://atbogdan.com/portfolio/lucky-shrub',
  },
  {
    title: 'Unigifts',
    description:
      'Online shop built using Wordpress, Woocommerce and Pagelayer. Logo designed using Adobe Photoshop and Illustrator.',
    getImageSrc: () => require('../images/unigifts-logo.png'),
    url: 'https://unigifts.ro',
  },
  {
    title: 'Meta Front-End Developer Certificate',
    description:
      'Learned building web applications using technologies such as HTML, CSS, Javascript, and React. I have also studied and used technologies such as Tailwind CSS, Bootstrap, Chakra UI, GSAP, Next Js and Git.',
    getImageSrc: () => require('../images/meta.png'),
    url: 'https://coursera.org/verify/professional-cert/5H7KPLZL9C53',
  },
  {
    title: 'Repository Maintainer',
    description:
      "Repository maintainer @ javascript.info where we are translating articles into Romanian. My responsibilities are translating, recommending improvements and approving other contributors' translations.",
    getImageSrc: () => require('../images/javascript.info.png'),
    url: 'https://github.com/javascript-tutorial/ro.javascript.info',
  },
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      backgroundColor='gray.700'
      isDarkBackground
      p={8}
      alignItems='center'
      spacing={8}
      id='projects-section'
    >
      <Heading data-aos='zoom-in' as='h1'>
        Projects
      </Heading>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={8} px={[2, 16]}>
        {projects.map((project) => (
          <CardComponent
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            url={project.url}
          />
        ))}
      </SimpleGrid>
    </FullScreenSection>
  );
};

export default ProjectsSection;
