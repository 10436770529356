import { useState, useEffect } from 'react';
import { useIntersection } from 'react-use';

const useOnScreen = (
  ref,
  options = { root: null, rootMargin: '0px', threshold: 0.1 }
) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const intersection = useIntersection(ref, options);

  useEffect(() => {
    if (intersection) {
      setIntersecting(intersection.isIntersecting);
    }
  }, [intersection]);

  return isIntersecting;
};

export default useOnScreen;
