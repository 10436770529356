import { Image, VStack } from '@chakra-ui/react';
import FullScreenSection from './FullScreenSection';
import video from '../images/cover.mp4';
import logo from '../images/logo.webp';

const LandingSection = () => (
  <FullScreenSection justifyContent='center' alignItems='center'>
    <video
      autoPlay
      muted
      loop
      id='video'
      style={{
        position: 'absolute',
        objectFit: 'cover',
        zIndex: '-1',
        width: '100%',
        height: '100vh',
        filter: 'brightness(90%)',
      }}
    >
      <source src={video} type='video/mp4' />
    </video>

    <VStack>
      <Image className='spin' src={logo} boxSize='300px' paddingTop='8vh' />
    </VStack>
  </FullScreenSection>
);

export default LandingSection;
