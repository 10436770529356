import { ChakraProvider } from '@chakra-ui/react';
import Header from './components/Header';
import LandingSection from './components/LandingSection';
import ProjectsSection from './components/ProjectsSection';
import ContactMeSection from './components/ContactMeSection';
import AboutMeSection from './components/AboutMeSection';
import Footer from './components/Footer';
import { AlertProvider } from './context/alertContext';
import Alert from './components/Alert';
import React, { useRef } from 'react';
import useOnScreen from './hooks/useOnScreen';

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  //hides nav link of the component the user is watching from the header
  const contactRef = useRef();
  const projectsRef = useRef();
  const isContactOnScreen = useOnScreen(contactRef);
  const isProjectsOnScreen = useOnScreen(projectsRef);
  AOS.init();

  return (
    <ChakraProvider>
      <AlertProvider>
        <main>
          <Header
            isContactOnScreen={isContactOnScreen}
            isProjectsOnScreen={isProjectsOnScreen}
          />
          <LandingSection />
          <AboutMeSection />
          <div ref={projectsRef}>
            <ProjectsSection />
          </div>
          <div ref={contactRef}>
            <ContactMeSection />
          </div>
          <Footer />
          <Alert />
        </main>
      </AlertProvider>
    </ChakraProvider>
  );
}

export default App;
