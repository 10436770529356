import { useState } from 'react';
import emailjs from 'emailjs-com';

const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (data) => {
    setLoading(true);
    try {
      // send the email using emailjs
      await emailjs.send(
        'service_leg335v', // service ID
        'template_10vegy8', // template ID
        data, // form data
        '9ljjwKKCeXiULC7Mn' // public key
      );
      setResponse({
        type: 'success',
        message: `Thanks for your submission ${data.firstName}, we will get back to you shortly!`,
      });
    } catch (error) {
      setResponse({
        type: 'error',
        message: 'Something went wrong, please try again later!',
      });
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
};

export default useSubmit;
